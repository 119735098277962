import "./src/styles/global.css"
import "@fontsource/roboto";

export const onRouteUpdate = () => {
    if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
        navigator.serviceWorker.register('/sw.js').then((reg) => {
            reg.update();
        });
    }
};
export const onServiceWorkerUpdateReady = () => {
    window.location.reload(true)
};