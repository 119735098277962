exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-offer-js": () => import("./../../../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-users-js": () => import("./../../../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */),
  "component---src-templates-index-reviews-js": () => import("./../../../src/templates/indexReviews.js" /* webpackChunkName: "component---src-templates-index-reviews-js" */),
  "component---src-templates-page-element-js": () => import("./../../../src/templates/pageElement.js" /* webpackChunkName: "component---src-templates-page-element-js" */),
  "component---src-templates-page-review-js": () => import("./../../../src/templates/pageReview.js" /* webpackChunkName: "component---src-templates-page-review-js" */),
  "component---src-templates-page-tag-js": () => import("./../../../src/templates/pageTag.js" /* webpackChunkName: "component---src-templates-page-tag-js" */)
}

